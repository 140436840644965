import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import { createFirestoreInstance, reduxFirestore } from 'redux-firestore';
import rootReducer from './store/reducers/rootReducer';
import reportWebVitals from './reportWebVitals';
import App from './App';
import firebase from './config/firebaseConfig';

const rrfConfig = {
  userProfile: 'user',
  useFirestoreForProfile: true,
  attachAuthIsReady: true,
};

const store = createStore(rootReducer, compose(applyMiddleware(thunk.withExtraArgument({ getFirebase, reduxFirestore }))));

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
