import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import profileReducer from './authReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export default rootReducer;
