import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBGePueGnNijUiFJVEu_rTSObXBvGWEgQ4',
  authDomain: 'dive-s-port.firebaseapp.com',
  projectId: 'dive-s-port',
  storageBucket: 'dive-s-port.appspot.com',
  messagingSenderId: '259312982328',
  appId: '1:259312982328:web:e96eef824c8ef64f2f89cd',
  measurementId: 'G-9CMY89XEP4',
};
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshotes: true });

export default firebase;
