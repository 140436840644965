import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          Unfortuntely s-portme is no longer running, any existing 1:1s will still take place
        </p>
        <p>
          For questions, you can reach us at diver.s.port.team@gmail.com. Thanks everyone for your support!
        </p>
      </header>
    </div>
  );
}

export default App;
